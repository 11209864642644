import { gql } from "@apollo/client";


export const GET_SIMPLY_MERCHANTS = gql`
    query SimplyMerchants($isMerchant: Boolean!){
        simplyMerchants(isMerchant:$isMerchant){
            id,
            name,
            country{
                id,
                currencyCode
            },
            cryptoType
        }
    }
`;

export const GET_FULL_SIMPLY_MERCHANTS = gql`
    query SimplyMerchants($isMerchant: Boolean!){
        simplyMerchants(isMerchant:$isMerchant){
            id,
            name,
            country{
                id,
                name,
                currencyCode
            },
            MerchantCharges{
                currencyCode,
                chargePercent,
                chargeFlat,
                chargeFor
            },
            ip,
            apiKey,
            secretKey,
            balance,
            availableBalance
        }
    }
`;

export const GET_MERCHANTS = gql`
    query Merchants($bFilterInput: MerchantFilterInput!){
        merchants(bFilterInput: $bFilterInput){
            count(bFilterInput: $bFilterInput),
            merchants{
                id,
                name,
                country{
                    id,
                    name,
                    currencyCode
                },
                MerchantCharges{
                    currencyCode,
                    chargePercent,
                    chargeFlat,
                    chargeFor
                },
                ip,
                apiKey,
                secretKey,
                balance,
                availableBalance,
                crypto,
                cryptoType,
                singleAddress,
                createdAt,
                createdBySystemUser{
                    userName
                },
                updatedAt,
                updatedBySystemUser{
                    userName
                }
            }
        }
    }
`;

export const GET_MERCHANT = gql`
    query Merchant($id: ID!){
        merchant(id:$id){
            id,
            name,
            country{
                id,
                name,
                currencyCode
            },
            MerchantCharges{
                currencyCode,
                chargePercent,
                chargeFlat,
                chargeFor
            },
            ip,
            apiKey,
            secretKey,
            balance,
            availableBalance,
            crypto,
            cryptoType,
            singleAddress,
            createdAt,
            createdBySystemUser{
                userName
            },
            updatedAt,
            updatedBySystemUser{
                userName
            },
            MerchantBanks{
                id,
                bankAccount{
                    bankName,
                    accountName,
                    accountNumber,
                }
            }
        }
    }
`;

export const GET_SELECTION_MERCHANTS = gql`
    query SelectionMerchants{
        selectionMerchants{
            id,
            name
        }
    }
`;

export const CREATE_MERCHANT = gql`
    mutation CreateMerchant($bInput:MerchantInput!){
        createMerchant(bInput:$bInput){
            status,
            id
        }
    }
`;

export const UPDATE_MERCHANT = gql`
    mutation UpdateMerchant($bInput:MerchantInput!){
        updateMerchant(bInput:$bInput){
            status,
            id
        }
    }
`;

export const DELETE_MERCHANT = gql`
    mutation DeleteMerchant($id: ID!){
        deleteMerchant(id:$id){
            status,
            id
        }
    }
`;

export const GET_CRYPTO_MERCHANTS = gql`
    query GetCryptoMerchants{
        getCryptoMerchants{
            id,
            name,
            cryptoType
        }
    }
`;